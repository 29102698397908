<template>
  <widget-card>
    <header-section
      title="Paliwo"
      action="+ Raport"
      @actionClick="openReportModal"
      :canAction="$store.getters['auth/userHasPermission']('store', 'base.resource_report')"
    >
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="entry" v-if="latestRefuelingReport">
              <div class="title">ostatnie tankowanie</div>
              <div class="value">
                <div class="user-label">
                  <user-label isEmployee withAvatar showLink
                    :data="latestRefuelingReport.reporter"
                  />
                </div>
                <div class="fuel-amount">
                  {{ latestRefuelingReport.amount_fuel }} l
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="12">
            <div>
              <vue-apex-charts
                class="mthChart"
                type="bar"
                height="200"
                :options="apexOptions"
                :series="apexSeries"
              />
            </div>
          </b-col>

        </b-row>
      </b-container>
    </header-section>

    <b-button
      variant="link"
      @click="$router.push(reportRoute)"
      >
      <feather-icon
        icon="LinkIcon"
        size="16"
      />
      Przejdź do listy raportów
    </b-button>

  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import VueApexCharts from 'vue-apexcharts';
import {
  BButton,
  BContainer,
  BRow,
  BCol,
} from 'bootstrap-vue';
import { computed, inject } from 'vue';
import formatDate from '@/libs/date-formatter';
import HeaderSection from '@/components/ui/HeaderSection.vue';
import useMachineReports from '@/hooks/base/machine/useMachineReports';
import useMachineMthCard from '@/hooks/base/machine/useMachineMthCard';
import UserLabel from '@/components/ui/UserLabel.vue';
import useBaseModals from '@/hooks/base/useBaseModals';

export default {
  name: 'MachineRefuelingCard',
  setup() {
    const machine = inject('machine');
    const { report, currentMeterStatus, updateChart } = useMachineMthCard();

    updateChart(machine.value.id);

    const getDaysArray = (days) => {
      const today = new Date();
      const daysAgo = new Date();
      daysAgo.setDate(daysAgo.getDate() - days + 1);

      const arr = [];
      for (let dt = new Date(daysAgo); dt <= today; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
      }

      return arr;
    };

    const lastTenDays = getDaysArray(10);

    // get day labels for each day
    const categories = computed(() => lastTenDays
      .map((day) => formatDate.reportChart(day)));

    const apexOptions = computed(() => ({
      theme: {
        palette: 'palette13',
      },
      xaxis: {
        categories: categories.value,
      },
    }));

    // get fuel amounts for each day
    const values = computed(() => {
      if (!report.value.values) return null;

      return lastTenDays
        .map((day) => {
          const s = (date) => formatDate.reportChart(date);

          const reportedDayFuelAmount = report.value.values
            .filter((rep) => s(rep.report_date) === s(day) && !!rep.amount_fuel)
            .reduce((prev, curr) => prev + Number(curr.amount_fuel), 0);

          return reportedDayFuelAmount;
        });
    });

    const apexSeries = computed(() => ([{
      name: 'Raportowane paliwo',
      data: values.value ?? [],
    }]));

    const latestRefuelingReport = computed(() => [...report.value?.values ?? []]
      .sort((a, b) => {
        const d1 = new Date(a.report_date);
        const d2 = new Date(b.report_date);
        return d2.getTime() - d1.getTime();
      })
      .find((r) => r.amount_fuel));

    const { fuelReportModalVisible } = useMachineReports();

    const reportRoute = computed(() => {
      const resourceType = machine.value.type?.slug === 'tool' ? 'equipment' : 'machines';
      const { id } = machine.value;

      const route = {
        name: `base.${resourceType}.reports`,
        params: {
          resourceId: id,
          reportType: 'fuel',
        },
      };

      return route;
    });

    const { modalCreateReport } = useBaseModals();
    const openReportModal = () => {
      modalCreateReport.value.open({ reportFuel: true, base_resource_id: machine.value.id });
    };

    return {
      machine,
      report,
      categories,
      apexOptions,
      apexSeries,
      values,
      currentMeterStatus,
      fuelReportModalVisible,
      latestRefuelingReport,
      reportRoute,
      openReportModal,
    };
  },
  components: {
    WidgetCard,
    VueApexCharts,

    BButton,
    BContainer,
    BRow,
    BCol,
    HeaderSection,
    UserLabel,
  },
};
</script>

<style lang="sass" scoped>
.entry
  .value
    display: flex
    align-items: center
    font-size: 14pt
    font-weight: 500
    margin-top: 10px

    .fuel-amount
      padding-left: 8px

      &:before
        content: '-'
        margin-right: 3px
</style>
