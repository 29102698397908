var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', [_c('header-section', {
    attrs: {
      "title": "Paliwo",
      "action": "+ Raport",
      "canAction": _vm.$store.getters['auth/userHasPermission']('store', 'base.resource_report')
    },
    on: {
      "actionClick": _vm.openReportModal
    }
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.latestRefuelingReport ? _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("ostatnie tankowanie")]), _c('div', {
    staticClass: "value"
  }, [_c('div', {
    staticClass: "user-label"
  }, [_c('user-label', {
    attrs: {
      "isEmployee": "",
      "withAvatar": "",
      "showLink": "",
      "data": _vm.latestRefuelingReport.reporter
    }
  })], 1), _c('div', {
    staticClass: "fuel-amount"
  }, [_vm._v(" " + _vm._s(_vm.latestRefuelingReport.amount_fuel) + " l ")])])]) : _vm._e()]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('vue-apex-charts', {
    staticClass: "mthChart",
    attrs: {
      "type": "bar",
      "height": "200",
      "options": _vm.apexOptions,
      "series": _vm.apexSeries
    }
  })], 1)])], 1)], 1)], 1), _c('b-button', {
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push(_vm.reportRoute);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "LinkIcon",
      "size": "16"
    }
  }), _vm._v(" Przejdź do listy raportów ")], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }