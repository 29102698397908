var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    staticClass: "machine-details-card",
    attrs: {
      "title": _vm.machine.name,
      "with-controls": "",
      "editPermission": `${_vm.permissionSet}.update`,
      "deletePermission": `${_vm.permissionSet}.destroy`
    },
    on: {
      "edit": function ($event) {
        return _vm.$router.push({
          name: `base.${_vm.resourceType}.details.settings`
        });
      },
      "delete": _vm.deleteResource
    }
  }, [_c('machine-details-table', {
    attrs: {
      "machine": _vm.machine
    }
  }), _c('div', {
    staticClass: "additional-buttons"
  }, [_vm.reportable ? _c('vs-button', {
    directives: [{
      name: "can",
      rawName: "v-can:store",
      value: 'base.resource_report',
      expression: "'base.resource_report'",
      arg: "store"
    }],
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.openFuelReportModal
    }
  }, [_vm._v(" + Tankowanie ")]) : _vm._e(), _vm.reportable ? _c('vs-button', {
    directives: [{
      name: "can",
      rawName: "v-can:store",
      value: 'base.resource_report',
      expression: "'base.resource_report'",
      arg: "store"
    }],
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.openMthReportModal
    }
  }, [_vm._v(" + Przebieg ")]) : _vm._e(), _c('vs-button', {
    directives: [{
      name: "can",
      rawName: "v-can:store",
      value: 'base.resource_report',
      expression: "'base.resource_report'",
      arg: "store"
    }],
    staticClass: "qr-button",
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": function ($event) {
        _vm.modalResourceQR.visible = true;
      }
    }
  }, [_c('feather-icon', {
    staticClass: "qr-button-icon",
    attrs: {
      "icon": "DivideSquareIcon"
    }
  }), _vm._v(" Pobierz kod QR ")], 1)], 1), _c('base-resource-qr-modal', {
    attrs: {
      "resource": _vm.machine
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }