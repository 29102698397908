var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-modal', {
    attrs: {
      "modalInstance": _vm.modalAttachResource
    },
    on: {
      "ok": _vm.assignResources
    }
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.feedback,
      "i18nNamespace": "form.base.assignResource"
    },
    model: {
      value: _vm.fields,
      callback: function ($$v) {
        _vm.fields = $$v;
      },
      expression: "fields"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "base_first_resource_id"
    }
  }, [_c('base-resource-picker', {
    attrs: {
      "excluded": _vm.firstResourceExclude
    },
    model: {
      value: _vm.fields.base_first_resource_id,
      callback: function ($$v) {
        _vm.$set(_vm.fields, "base_first_resource_id", $$v);
      },
      expression: "fields.base_first_resource_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "base_second_resource_id"
    }
  }, [_c('base-resource-picker', {
    attrs: {
      "multiple": "",
      "excluded": _vm.secondResourceExclude
    },
    model: {
      value: _vm.fields.base_second_resource_id,
      callback: function ($$v) {
        _vm.$set(_vm.fields, "base_second_resource_id", $$v);
      },
      expression: "fields.base_second_resource_id"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }