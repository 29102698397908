import i18n from '@/libs/i18n';
import showToast from '@/libs/toasts';
import {
  AssignMachineToolError,
  AssignMachineToolSuccess,
  CreateMachineError,
  CreateMachineSuccess,
  DeleteMachineError,
  DeleteMachineSuccess,
  FetchPickerMachinesError,
  UnassignMachineToolError,
  UnassignMachineToolSuccess,
  UpdateMachineError,
  UpdateMachineSuccess,
} from '@/libs/toasts/feedback/module/base';
import { deleteModal } from '@/libs/modals';
import router from '@/router';
import machineTable from '@/hooks/tables/base/machines';
import { computed, ref } from 'vue';
import useBaseApi from '../useBaseApi';
import useBaseModals from '../useBaseModals';
import useMachineForm from './useMachineForm';

const pickerMachines = ref([]);

export default function useMachine() {
  const machineForm = useMachineForm();

  const {
    form, validateAll, reassignFields,
  } = machineForm;

  // TODO refactor the form validation hook
  const formErrors = ref({});

  const { machineAdd } = useBaseModals();
  const isLoading = ref(false);

  const resetForm = () => {
    reassignFields({});
  };

  const createMachine = () => {
    isLoading.value = true;

    const sendData = {
      ...form.value,
      base_resource_activity: {
        base_group_id: form.value.base_group_id,
      },
      base_resource_type_id: 'vehicle',
    };

    if (form.value.crm_company_id) {
      sendData.base_resource_activity = {
        ...sendData.base_resource_activity,
        base_resource_collaboration: {
          crm_company_id: form.value.crm_company_id,
          start_date: form.value.start_date,
          end_date: form.value.end_date,
        },
      };
    }

    const req = useBaseApi()
      .createMachine(sendData)
      .then(({ data }) => {
        isLoading.value = false;

        showToast(CreateMachineSuccess, { name: form.value.name }, () => {
          router.push({
            name: 'base.machines.details',
            params: {
              id: data.data.id,
            },
          });
        });
        machineAdd.value = false;
        machineTable.value.fetchData();
      })
      .catch(({ response }) => {
        isLoading.value = false;
        formErrors.value = response.data?.errors;
        showToast(CreateMachineError);
      });

    return req;
  };

  const fetchPickerMachines = (force = false) => {
    if (pickerMachines.value.length > 0 && !force) return pickerMachines;

    const req = useBaseApi()
      .fetchPickerMachines()
      .then(({ data }) => {
        pickerMachines.value = data.data;
      })
      .catch(() => showToast(FetchPickerMachinesError));

    return req;
  };

  const updateMachine = () => {
    validateAll();

    isLoading.value = true;

    const sendData = {
      ...form.value,
      register_number: 'asdf',
      base_resource_type_id: 'vehicle',
      base_resource_activity: {
        base_group_id: form.value.base_group_id,
      },
    };
    delete sendData.id;
    delete sendData.base_group_id;
    delete sendData.vehicle_id;

    if (form.value.crm_company_id) {
      sendData.base_resource_activity = {
        ...sendData.base_resource_activity,
        base_resource_collaboration: {
          crm_company_id: form.value.crm_company_id,
          start_date: form.value.start_date,
          end_date: form.value.end_date,
        },
      };
    }

    const req = useBaseApi()
      .updateMachine(form.value.id, sendData)
      .then(() => {
        isLoading.value = false;
        showToast(UpdateMachineSuccess, { name: form.value.name });
        fetchPickerMachines(true);
        formErrors.value = {};
      })
      .catch(({ response }) => {
        isLoading.value = false;
        formErrors.value = response.data?.errors;
        showToast(UpdateMachineError);
      });

    return req;
  };

  const deleteMachine = async (machine) => {
    const confirmation = await deleteModal({
      title: i18n.t('base.machines.modal.delete.title'),
      text: i18n.t('base.machines.modal.delete.text', {
        name: machine.name,
      }),
    });

    if (!confirmation) return false;

    const req = useBaseApi()
      .deleteMachine(machine.vehicle_id)
      .then(() => {
        showToast(DeleteMachineSuccess, { name: machine.name });
        router.push({ name: 'base.machines' });
      })
      .catch(() => {
        showToast(DeleteMachineError);
      });

    return req;
  };

  const fetchMachine = (machineId) => {
    const req = useBaseApi()
      .fetchMachine(machineId);

    return req;
  };

  const assignTool = (machineId, toolIds) => {
    const req = useBaseApi()
      .assignMachineTool(machineId, toolIds)
      .then(() => {
        showToast(AssignMachineToolSuccess, { name: machineId });

        const { machineAssignTool } = useBaseModals();
        machineAssignTool.value = false;
      })
      .catch(() => {
        showToast(AssignMachineToolError);
      });

    return req;
  };

  const unassignTool = async (machineId, tool, machine) => {
    if (!machineId || !tool) return null;

    const confirmation = await deleteModal({
      title: i18n.t('base.machines.modal.unassignTool.title'),
      text: i18n.t('base.machines.modal.unassignTool.text', {
        tool: tool.resource.name,
        machine: machine.resource.name,
      }),
    });

    if (!confirmation) return new Promise((_, reject) => reject());

    const req = useBaseApi()
      .unassignMachineTool(machineId, [tool.id])
      .then(() => {
        showToast(UnassignMachineToolSuccess, { name: tool.resource.name });

        const { machineAssignTool } = useBaseModals();
        machineAssignTool.value = false;
      })
      .catch(() => {
        showToast(UnassignMachineToolError);
      });

    return req;
  };

  const feedback = computed(() => {
    const errorList = {};

    Object.keys(formErrors.value).forEach((error) => {
      if (!formErrors.value?.[error]) return;
      errorList[error] = formErrors.value?.[error]?.[0];
    });

    return errorList;
  });

  return {
    form,
    feedback,
    modalVisible: machineAdd,
    isLoading,

    createMachine,
    updateMachine,
    deleteMachine,
    fetchMachine,
    reassignFields,

    assignTool,
    unassignTool,

    fetchPickerMachines,
    pickerMachines,
    resetForm,
  };
}
