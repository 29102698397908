<template>
  <widget-card
    :title="machine.name"

    with-controls
    :editPermission="`${permissionSet}.update`"
    :deletePermission="`${permissionSet}.destroy`"
    @edit="$router.push({ name: `base.${resourceType}.details.settings` })"
    @delete="deleteResource"
    class="machine-details-card"
  >

    <machine-details-table :machine="machine"/>

    <div class="additional-buttons">
      <vs-button
        vs-variant="primary"
        @click="openFuelReportModal"
        v-if="reportable"
        v-can:store="'base.resource_report'"
      >
        + Tankowanie
      </vs-button>

      <vs-button
        vs-variant="primary"
        @click="openMthReportModal"
        v-if="reportable"
        v-can:store="'base.resource_report'"
      >
        + Przebieg
      </vs-button>

      <vs-button
        vs-variant="light"
        class="qr-button"
        @click="modalResourceQR.visible = true"
        v-can:store="'base.resource_report'"
      >
        <feather-icon
          icon="DivideSquareIcon"
          class="qr-button-icon"
        />
        Pobierz kod QR
      </vs-button>
    </div>

    <base-resource-qr-modal
      :resource="machine"
    />
  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import MachineDetailsTable from '@/components/views/base/machines/ui/MachineDetailsTable.vue';
import { computed, inject } from 'vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import useBaseApi from '@/hooks/base/useBaseApi';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import showToast from '@/libs/toasts';
import { useRouter } from '@/@core/utils/utils';
import { DeleteResourceError, DeleteResourceSuccess } from '@/libs/toasts/feedback/module/base';
import BaseResourceQRModal from '../../resource/modals/BaseResourceQRModal.vue';

export default {
  name: 'MachineDetailsCard',
  props: {
    forEquipment: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const machine = inject('machine');
    const { modalResourceQR, modalCreateReport } = useBaseModals();

    const resourceType = computed(() => (props.forEquipment ? 'equipment' : 'machines'));

    const { router } = useRouter();
    const deleteResource = async () => {
      const confirmation = await deleteModal({
        title: i18n.t('base.machines.modal.delete.title'),
        text: i18n.t('base.machines.modal.delete.text', {
          name: machine.value.name,
        }),
      });

      if (!confirmation) return;

      useBaseApi()
        .deleteResource(machine.value.id)
        .then(() => {
          showToast(DeleteResourceSuccess, { name: machine.value.name });
          router.push({ name: `base.${resourceType.value}` });
        })
        .catch(() => {
          showToast(DeleteResourceError);
        });
    };

    const permissionSet = 'base.resource';

    const openMthReportModal = () => {
      modalCreateReport.value.open({
        base_resource_id: machine.value.id,
      });
    };

    const openFuelReportModal = () => {
      modalCreateReport.value.open({
        reportFuel: true,
        base_resource_id: machine.value.id,
      });
    };

    const reportable = computed(() => machine.value?.category?.be_reported);

    return {
      machine,
      resourceType,
      deleteResource,
      modalResourceQR,
      permissionSet,
      openMthReportModal,
      openFuelReportModal,
      reportable,
    };
  },
  components: {
    MachineDetailsTable,
    WidgetCard,
    VsButton,
    'base-resource-qr-modal': BaseResourceQRModal,
  },
};
</script>

<style lang="sass" scoped>
.machine-details-card ::v-deep
  .card-body
    display: flex
    flex-direction: column

.additional-buttons
  margin-top: auto
  display: flex
  align-items: flex-start
  flex-wrap: wrap
  gap: 5px

  *
    flex-shrink: 0

.qr-button
  &-icon
    margin-right: 10px
</style>
