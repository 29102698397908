<template>
  <widget-card>
    <header-section
      title="Stan licznika"
      action="+ Raport"
      @actionClick="openReportModal"
      :canAction="$store.getters['auth/userHasPermission']('store', 'base.resource_report')"
    >
      <b-container
        fluid
        >
        <b-row>
          <b-col cols="12">
            <machines-moto-hours-table
              :meter-status="currentMeterStatus"
            />
          </b-col>
          <b-col cols="12">
            <div>
              <vue-apex-charts
                class="mthChart"
                type="line"
                height="200"
                :options="apexOptions"
                :series="apexSeries"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </header-section>

    <b-button
      variant="link"
      @click="$router.push(reportRoute)"
      v-can:show="'base.resource_report'"
      >
      <feather-icon
        icon="LinkIcon"
        size="16"
      />
      Przejdź do listy raportów
    </b-button>

  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import VueApexCharts from 'vue-apexcharts';
import {
  BButton,
  BContainer,
  BRow,
  BCol,
} from 'bootstrap-vue';
import MachinesMotoHoursTable from '@/components/views/base/machines/ui/MachinesMotoHoursTable.vue';
import { computed, inject } from 'vue';
import formatDate from '@/libs/date-formatter';
import HeaderSection from '@/components/ui/HeaderSection.vue';
import useMachineReports from '@/hooks/base/machine/useMachineReports';
import useMachineMthCard from '@/hooks/base/machine/useMachineMthCard';
import useBaseModals from '@/hooks/base/useBaseModals';

export default {
  name: 'MachinesMotoHoursCard',
  setup() {
    const machine = inject('machine');
    const { report, currentMeterStatus, updateChart } = useMachineMthCard();

    updateChart(machine.value.id);

    const getDaysArray = (days) => {
      const today = new Date();
      const daysAgo = new Date();
      daysAgo.setDate(daysAgo.getDate() - days + 1);

      const arr = [];
      for (let dt = new Date(daysAgo); dt <= today; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
      }

      return arr;
    };

    const lastTenDays = getDaysArray(10);

    // get day labels for each day
    const categories = computed(() => lastTenDays
      .map((day) => formatDate.reportChart(day)));

    const getFilename = () => {
      const firstDay = formatDate.filename(lastTenDays[0]);
      const lastDay = formatDate.filename(lastTenDays.at(-1));

      return `${machine.value.name} - mth (${firstDay} - ${lastDay})`;
    };

    const apexOptions = computed(() => ({
      theme: {
        palette: 'palette13',
      },
      xaxis: {
        categories: categories.value,
      },
      chart: {
        toolbar: {
          export: {
            png: { filename: getFilename() },
            csv: { filename: getFilename() },
            svg: { filename: getFilename() },
          },
          tools: {
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
    }));

    // get mth amounts for each day
    const values = computed(() => {
      if (!report.value.values) return null;
      let highestRecordedMeterStatus = Number(report.value?.prior_meter ?? 0);

      return lastTenDays
        .map((day) => {
          const s = (date) => formatDate.reportChart(date);

          const dayMeterStatuses = report.value.values
            .filter((rep) => s(rep.report_date) === s(day))
            .map((rep) => Number(rep.meter_status));

          const dayHighest = dayMeterStatuses.length ? Math.max(...dayMeterStatuses) : 0;

          if (dayHighest === 0) return highestRecordedMeterStatus;

          highestRecordedMeterStatus = dayHighest;
          return dayHighest;
        });
    });

    const apexSeries = computed(() => ([{
      name: 'Stan licznika',
      data: values.value ?? [],
    }]));

    const { mthReportModalVisible } = useMachineReports();

    const reportRoute = computed(() => {
      const resourceType = machine.value.type?.slug === 'tool' ? 'equipment' : 'machines';
      const { id } = machine.value;

      const route = {
        name: `base.${resourceType}.reports`,
        params: {
          resourceId: id,
          reportType: 'work',
        },
      };

      return route;
    });

    const { modalCreateReport } = useBaseModals();
    const openReportModal = () => {
      modalCreateReport.value.open({ base_resource_id: machine.value.id });
    };

    return {
      machine,
      report,
      categories,
      apexOptions,
      apexSeries,
      values,
      currentMeterStatus,
      mthReportModalVisible,
      reportRoute,
      openReportModal,
    };
  },
  components: {
    MachinesMotoHoursTable,
    WidgetCard,
    VueApexCharts,

    BButton,
    BContainer,
    BRow,
    BCol,
    HeaderSection,
  },
};
</script>
