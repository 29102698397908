var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "equipment"
  }, [_c('div', {
    staticClass: "equipment-footer"
  }, [_c('base-resource-label', {
    attrs: {
      "resource": _vm.equipment,
      "type": _vm.equipment.type.slug
    }
  }), _c('div', {
    staticClass: "type"
  }, [_vm._v(" " + _vm._s(_vm.equipment.type.name === 'tool' ? 'Sprzęt' : 'Maszyna') + " ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }