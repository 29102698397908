<template>
  <table>
    <tbody>
      <tr>
        <td>status</td>
        <td>
          <status-machine-badge :status_id="machine.base_status_id"/>
        </td>
      </tr>
      <tr>
        <td>grupa</td>
        <td v-if="machine.last_activity">
          {{ machine.last_activity?.group?.name }}
        </td>
      </tr>
      <tr>
        <td>producent</td>
        <td v-if="machine.producer_model">
          {{ machine.producer_model.producer.name }}
        </td>
      </tr>
      <tr>
        <td>model</td>
        <td v-if="machine.producer_model">
          {{ machine.producer_model.name }}
        </td>
      </tr>
      <tr>
        <td>rok produkcji</td>
        <td>
          {{ machine.production_date }}
        </td>
      </tr>
      <tr>
        <td>nr ewidencyjny</td>
        <td>
          {{ machine.serial_number }}
        </td>
      </tr>
      <tr>
        <td>kategoria</td>
        <td v-if="machine.category">
          {{ machine.category.name }}
        </td>
      </tr>
      <tr>
        <td>technologie</td>
        <td>
          <ul class="technology-list">
            <li
              class="technology-text"
              v-for="technology in machine.technologies" :key="technology.id"
              >
              {{ technology.name }}
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>koszt dzienny</td>
        <td>
          {{ `${(machine.actual_daily_cost || 0).toLocaleString()} PLN` }}
        </td>
      </tr>
      <template v-if="machine.last_activity?.collaboration?.crm_company_id">
        <tr>
          <td>wynajmujący</td>
          <td>
            <crm-company-label
              :integrated="machine.last_activity.collaboration.company"
              companyType="provider"
              short
            />
          </td>
        </tr>
        <tr>
          <td>okres wynajmu</td>
          <td>
            {{ formatDate.rent(machine.last_activity.collaboration.start_date) }}
            -
            {{ formatDate.rent(machine.last_activity.collaboration.end_date) }}
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import StatusMachineBadge from '@/components/ui/status-badge/Machine.vue';
import CrmCompanyLabel from '@/components/module/crm/CrmCompanyLabel.vue';
import formatDate from '@/libs/date-formatter';

export default {
  name: 'MachineDetailsTable',
  setup() {
    return { formatDate };
  },
  components: {
    StatusMachineBadge,
    CrmCompanyLabel,
  },
  props: {
    machine: {
      type: Object,
    },
  },
};
</script>

<style lang="sass" scoped>
table
  line-height: 1.7
  margin-bottom: 10px

  td:nth-child(1)
    font-weight: 300
    width: 190px

  td:nth-child(2)
    padding-left: 20px

.technology-list
  list-style: none
  margin: 0
  padding: 0

  li
    display: inline-block

.technology-text:not(:first-child):before
  content: ', '

</style>
