var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-collapse', _vm._l(_vm.list, function (tool) {
    return _c('machine-equipment-item', {
      key: tool.id,
      attrs: {
        "equipment": tool,
        "forEquipment": _vm.forEquipment
      },
      on: {
        "remove": _vm.remove
      }
    });
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }