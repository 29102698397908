var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "entries"
  }, [_c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("aktualny stan")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.meterStatus) + " " + _vm._s(_vm.unitShortcode))])]), _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("w momencie zakupu")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.bought))])]), _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("w momencie utworzenia kartoteki")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.filed) + " " + _vm._s(_vm.unitShortcode))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }