import useFieldValidation from '@/hooks/useFieldValidation';
import { computed, ref } from 'vue';

export default function useMachineForm() {
  const {
    fields, errors, validateAll, reassignFields,
  } = useFieldValidation({
    id: '',
    vehicle_id: '',
    name: 'required',
    production_date: 'required|digits:4',
    serial_number: 'required',
    base_status_id: 'required|integer',
    base_category_id: 'required|integer',
    base_producer_model_id: 'required|integer',
    base_group_id: 'required|integer',
    meter_status_card_index: 'required|integer',
    meter_status_buy: 'integer',
    base_technology_id: 'required',
    base_resource_daily_cost: '',
    crm_company_id: '',
    start_date: '',
    end_date: '',
  });

  const form = ref({ ...fields });

  const feedback = computed(() => {
    const feedbackList = {};

    Object.keys(errors)
      .forEach((key) => {
        feedbackList[key] = errors?.[key]?.[0];
      });

    return feedbackList;
  });

  return {
    form, feedback, validateAll, reassignFields, errors,
  };
}
