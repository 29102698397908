<template>
  <vs-modal
    :modalInstance="modalAttachResource"
    @ok="assignResources"
  >
    <vs-form
      v-model="fields"
      :errors="feedback"
      i18nNamespace="form.base.assignResource"
    >

      <vs-form-group field="base_first_resource_id">
        <base-resource-picker
          v-model="fields.base_first_resource_id"
          :excluded="firstResourceExclude"
        />
      </vs-form-group>

      <vs-form-group field="base_second_resource_id">
        <base-resource-picker
          multiple
          v-model="fields.base_second_resource_id"
          :excluded="secondResourceExclude"
        />
      </vs-form-group>

    </vs-form>
  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import useValidatedForm from '@/hooks/useValidatedForm';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import BaseResourcePicker from '@/components/module/base/BaseResourcePicker.vue';
import useBaseApi from '@/hooks/base/useBaseApi';
import showToast from '@/libs/toasts';
import { AttachResourceSuccess, AttachResourceError } from '@/libs/toasts/feedback/module/base';

export default {
  name: 'BaseResourceAttachResourceModal',
  emits: ['ok'],
  props: {
    subjectTypes: {
      // limit "first_resource" selection
      type: Array,
    },
    assignableTypes: {
      // limit "second_resource" selection
      type: Array,
    },
  },
  setup(props, { emit }) {
    const { modalAttachResource } = useBaseModals();

    const {
      fields,
      feedback,
      areAllFieldsValid,
      assignServerSideErrors,
      assignFields,
      resetFields,
    } = useValidatedForm({
      base_first_resource_id: 'required',
      base_second_resource_id: '',
    });

    modalAttachResource.value.on({
      open: (data) => {
        resetFields();
        assignFields({ ...data });
      },
    });

    const assignResources = async () => {
      if (!(await areAllFieldsValid())) return;
      modalAttachResource.value.isLoading = true;

      useBaseApi()
        .attachResourcesToResource({ ...fields.value })
        .then(({ data }) => {
          showToast(AttachResourceSuccess);
          modalAttachResource.value.close();
          emit('ok', data.data);
        })
        .catch(({ response }) => {
          showToast(AttachResourceError);
          assignServerSideErrors(response?.data?.errors);
        })
        .finally(() => {
          modalAttachResource.value.isLoading = false;
        });
    };

    const firstResourceExclude = (r) => !props.subjectTypes
      || !props.subjectTypes.includes(r.type?.slug);

    const secondResourceExclude = (r) => !props.assignableTypes
      || !props.assignableTypes.includes(r.type?.slug);

    return {
      modalAttachResource,
      fields,
      feedback,
      firstResourceExclude,
      secondResourceExclude,
      assignResources,
    };
  },
  components: {
    VsModal,
    VsForm,
    VsFormGroup,
    BaseResourcePicker,
  },
};
</script>
