<template>
  <app-collapse>
    <machine-equipment-item
      v-for="tool in list"
      :key="tool.id"
      :equipment="tool"
      @remove="remove"
      :forEquipment="forEquipment"
    />
  </app-collapse>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import MachineEquipmentItem from '@/components/views/base/machines/ui/MachineEquipmentItem.vue';
import { computed, inject } from 'vue';

export default {
  name: 'MachineEquipmentsCollapse',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    forEquipment: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const machine = inject('machine');
    const tools = computed(() => machine.value.tools ?? []);

    const remove = (payload) => emit('remove', payload);

    return { tools, remove };
  },
  components: {
    MachineEquipmentItem,
    AppCollapse,
  },
};
</script>

<style scoped>

</style>
