<template>
  <widget-card>
    <header-section
      :title="cardTitle"
      action="Zmień"
      @actionClick="openModal"
      :canAction="!forEquipment &&
        $store.getters['auth/userHasPermission']('assign_tools', 'base.resource')"
    >
      <machine-equipments-collapse
        @remove="removeResourceAssignment"
        :list="assignedList"
      />

    </header-section>

    <div class="no-address-info" v-if="assignedList && !assignedList.length">
      <div class="wrapper">
        <feather-icon class="icon" icon="SettingsIcon" size="36" />

        <div class="text" v-if="!forEquipment">
          Maszyna nie ma jeszcze żadnego przypisanego sprzętu
        </div>

        <div class="text" v-else>
          Sprzęt nie jest jeszcze przypisany do żadnej maszyny
        </div>

        <a
          href="#"
          @click.prevent="openModal"
          v-can:assign_tools="'base.resource'"
          v-if="!forEquipment"
          >
          Kliknij tutaj, aby przypisać sprzęt
        </a>
      </div>
    </div>

    <base-resource-attach-resource-modal
      :subject-types="['vehicle']"
      :assignable-types="['tool']"
      @ok="$emit('attached:update')"
    />
  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import HeaderSection from '@/components/ui/HeaderSection.vue';
import useMachine from '@/hooks/base/machine/useMachine';
import { computed, inject } from 'vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import MachineEquipmentsCollapse from '../ui/MachineEquipmentsCollapse.vue';
import BaseResourceAttachResourceModal from '../modals/BaseResourceAttachResourceModal.vue';

export default {
  name: 'MachineEquipmentCard',
  emits: ['attached:update'],
  props: {
    forEquipment: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { unassignTool } = useMachine();
    const machine = inject('machine');

    const cardTitle = computed(() => {
      if (props.forEquipment) return 'Powiązane maszyny';
      return 'Powiązany sprzęt';
    });

    const assignedList = computed(() => (props.forEquipment
      ? machine.value.assigned_to_resource
      : machine.value.assigned_resource
    ));

    const { modalAttachResource } = useBaseModals();
    const openModal = () => {
      modalAttachResource.value.open({
        base_first_resource_id: machine.value.id,
        base_second_resource_id: machine.value.assigned_resource
          ?.map((r) => r.id),
      });
    };

    /**
     * TODO: Method below needs to be refactored, probably
     *       after some tweaks in the backend structure.
     *       The whole component still needs to be tweaked, as
     *       it points to both equipment and machine types.
     */
    const removeResourceAssignment = (resource) => {
      const vehicle = computed(() => {
        if (props.forEquipment) return resource;
        return { ...machine.value, resource: { name: machine.value.name } };
      });

      const vehicleId = computed(() => {
        if (props.forEquipment) return vehicle.value.id;
        return vehicle.value.vehicle_id;
      });

      const assignedResource = computed(() => {
        if (props.forEquipment) {
          return {
            ...machine.value,
            resource: { name: machine.value.name },
            id: machine.value.tool_id,
          };
        }
        return resource;
      });

      const resourceListName = computed(() => (props.forEquipment ? 'vehicles' : 'tools'));

      unassignTool(vehicleId.value, assignedResource.value, vehicle.value)
        .then(() => {
          const idToRemove = computed(() => {
            if (props.forEquipment) return vehicleId.value;
            return assignedResource.value.id;
          });

          machine.value[resourceListName.value] = machine.value[resourceListName.value]
            .filter(
              (oldResource) => oldResource.id !== idToRemove.value,
            );
        });
    };

    return {
      removeResourceAssignment, openModal, machine, assignedList, cardTitle,
    };
  },
  components: {
    MachineEquipmentsCollapse,
    HeaderSection,
    WidgetCard,
    BaseResourceAttachResourceModal,
  },
};
</script>

<style lang="sass" scoped>
.no-address-info
  display: flex
  justify-content: center
  padding-top: 10%
  text-align: center
  height: auto

  .wrapper
    .icon
      opacity: 0.3

    .text
      font-size: 12pt
      font-weight: 500
      opacity: 0.8
      margin: 20px 0
</style>
