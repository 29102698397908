var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', [_c('header-section', {
    attrs: {
      "title": _vm.cardTitle,
      "action": "Zmień",
      "canAction": !_vm.forEquipment && _vm.$store.getters['auth/userHasPermission']('assign_tools', 'base.resource')
    },
    on: {
      "actionClick": _vm.openModal
    }
  }, [_c('machine-equipments-collapse', {
    attrs: {
      "list": _vm.assignedList
    },
    on: {
      "remove": _vm.removeResourceAssignment
    }
  })], 1), _vm.assignedList && !_vm.assignedList.length ? _c('div', {
    staticClass: "no-address-info"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "SettingsIcon",
      "size": "36"
    }
  }), !_vm.forEquipment ? _c('div', {
    staticClass: "text"
  }, [_vm._v(" Maszyna nie ma jeszcze żadnego przypisanego sprzętu ")]) : _c('div', {
    staticClass: "text"
  }, [_vm._v(" Sprzęt nie jest jeszcze przypisany do żadnej maszyny ")]), !_vm.forEquipment ? _c('a', {
    directives: [{
      name: "can",
      rawName: "v-can:assign_tools",
      value: 'base.resource',
      expression: "'base.resource'",
      arg: "assign_tools"
    }],
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openModal.apply(null, arguments);
      }
    }
  }, [_vm._v(" Kliknij tutaj, aby przypisać sprzęt ")]) : _vm._e()], 1)]) : _vm._e(), _c('base-resource-attach-resource-modal', {
    attrs: {
      "subject-types": ['vehicle'],
      "assignable-types": ['tool']
    },
    on: {
      "ok": function ($event) {
        return _vm.$emit('attached:update');
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }