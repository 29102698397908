<template>
  <div class="entries">

    <div class="entry">
      <div class="title">aktualny stan</div>
      <div class="value">{{ meterStatus }} {{ unitShortcode }}</div>
    </div>

    <div class="entry">
      <div class="title">w momencie zakupu</div>
      <div class="value">{{ bought }}</div>
    </div>

    <div class="entry">
      <div class="title">w momencie utworzenia kartoteki</div>
      <div class="value">{{ filed }} {{ unitShortcode }}</div>
    </div>

  </div>
</template>

<script>
import { computed, inject } from 'vue';
import useCoreUnitStore from '@/stores/module/core/useCoreUnitStore';

export default {
  name: 'MachinesMotoHoursTable',
  props: {
    meterStatus: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const machine = inject('machine');
    const unitStore = useCoreUnitStore();

    const unitShortcode = computed(() => unitStore
      .getShortcode(machine.value?.category?.unit_id));

    const filed = computed(() => machine.value?.meter_status_card_index || 0);
    const bought = computed(() => {
      const value = machine.value?.meter_status_buy;
      if (value === null || value === undefined) return 'n / d';

      return `${value} ${unitShortcode.value}`;
    });

    return {
      machine,
      filed,
      bought,
      unitShortcode,
    };
  },
};
</script>

<style lang="sass" scoped>
.entries
  padding: 10px 0 20px 0
  display: flex
  align-items: center
  justify-content: space-between

  .entry
    .value
      font-size: 14pt
      font-weight: 500
</style>
