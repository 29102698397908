<template>
  <div class="equipment">
    <div class="equipment-footer">
      <base-resource-label
        :resource="equipment"
        :type="equipment.type.slug"
      />
      <div class="type">
        {{ equipment.type.name === 'tool' ? 'Sprzęt' : 'Maszyna' }}
      </div>
      <!--
      <div class="buttons">
        <b-button
          class="btn-icon"
          variant="flat-secondary"
          @click="remove"
        >
          <feather-icon icon="Trash2Icon" size="18"></feather-icon>
        </b-button>
      </div>
      -->
    </div>
  </div>
</template>

<script>
// import { BButton } from 'bootstrap-vue';
import { computed } from 'vue';
import BaseResourceLabel from '../../resource/BaseResourceLabel.vue';

export default {
  name: 'MachineEquipmentItem',
  props: {
    equipment: Object,
    forEquipment: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const resourceRoute = computed(() => {
      const name = props.forEquipment ? 'base.machines.details' : 'base.equipment.details';
      return {
        name,
        params: {
          id: props.equipment.id,
        },
      };
    });
    return {
      remove: () => emit('remove', props.equipment),
      resourceRoute,
    };
  },
  components: {
    BaseResourceLabel,
    // BButton,
  },
};
</script>

<style lang="sass" scoped>

.equipment
  padding: 15px 0
  &:not(:first-child)
    border-top: 1px solid rgba(black, 0.1)
    padding: 15px 0

.equipment-footer
  display: flex
  align-items: center
  justify-content: space-between

  .date
    margin-left: 5px
    opacity: 0.8

  .buttons
    margin-left: auto

</style>
