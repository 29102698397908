import { render, staticRenderFns } from "./MachineEquipmentsCollapse.vue?vue&type=template&id=67cd78b7&scoped=true&"
import script from "./MachineEquipmentsCollapse.vue?vue&type=script&lang=js&"
export * from "./MachineEquipmentsCollapse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67cd78b7",
  null
  
)

export default component.exports