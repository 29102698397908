var render = function render(){
  var _vm$machine$last_acti, _vm$machine$last_acti2, _vm$machine$last_acti3, _vm$machine$last_acti4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', [_c('tbody', [_c('tr', [_c('td', [_vm._v("status")]), _c('td', [_c('status-machine-badge', {
    attrs: {
      "status_id": _vm.machine.base_status_id
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("grupa")]), _vm.machine.last_activity ? _c('td', [_vm._v(" " + _vm._s((_vm$machine$last_acti = _vm.machine.last_activity) === null || _vm$machine$last_acti === void 0 ? void 0 : (_vm$machine$last_acti2 = _vm$machine$last_acti.group) === null || _vm$machine$last_acti2 === void 0 ? void 0 : _vm$machine$last_acti2.name) + " ")]) : _vm._e()]), _c('tr', [_c('td', [_vm._v("producent")]), _vm.machine.producer_model ? _c('td', [_vm._v(" " + _vm._s(_vm.machine.producer_model.producer.name) + " ")]) : _vm._e()]), _c('tr', [_c('td', [_vm._v("model")]), _vm.machine.producer_model ? _c('td', [_vm._v(" " + _vm._s(_vm.machine.producer_model.name) + " ")]) : _vm._e()]), _c('tr', [_c('td', [_vm._v("rok produkcji")]), _c('td', [_vm._v(" " + _vm._s(_vm.machine.production_date) + " ")])]), _c('tr', [_c('td', [_vm._v("nr ewidencyjny")]), _c('td', [_vm._v(" " + _vm._s(_vm.machine.serial_number) + " ")])]), _c('tr', [_c('td', [_vm._v("kategoria")]), _vm.machine.category ? _c('td', [_vm._v(" " + _vm._s(_vm.machine.category.name) + " ")]) : _vm._e()]), _c('tr', [_c('td', [_vm._v("technologie")]), _c('td', [_c('ul', {
    staticClass: "technology-list"
  }, _vm._l(_vm.machine.technologies, function (technology) {
    return _c('li', {
      key: technology.id,
      staticClass: "technology-text"
    }, [_vm._v(" " + _vm._s(technology.name) + " ")]);
  }), 0)])]), _c('tr', [_c('td', [_vm._v("koszt dzienny")]), _c('td', [_vm._v(" " + _vm._s(`${(_vm.machine.actual_daily_cost || 0).toLocaleString()} PLN`) + " ")])]), (_vm$machine$last_acti3 = _vm.machine.last_activity) !== null && _vm$machine$last_acti3 !== void 0 && (_vm$machine$last_acti4 = _vm$machine$last_acti3.collaboration) !== null && _vm$machine$last_acti4 !== void 0 && _vm$machine$last_acti4.crm_company_id ? [_c('tr', [_c('td', [_vm._v("wynajmujący")]), _c('td', [_c('crm-company-label', {
    attrs: {
      "integrated": _vm.machine.last_activity.collaboration.company,
      "companyType": "provider",
      "short": ""
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("okres wynajmu")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatDate.rent(_vm.machine.last_activity.collaboration.start_date)) + " - " + _vm._s(_vm.formatDate.rent(_vm.machine.last_activity.collaboration.end_date)) + " ")])])] : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }